module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Staatliches","file":"https://fonts.googleapis.com/css2?family=Staatliches"},{"name":"Roboto Condensed","file":"https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap"},{"name":"Oswald","file":"https://fonts.googleapis.com/css2?family=Oswald&display=swap"},{"name":"Londrina Outline","file":"https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
